import cn from 'classnames';
import { useState } from 'react';

const YesNo = ({ answer, answerPreview, sendAnswer }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const shownAnswer = answerPreview || answer;
  return (
    <div className="card">
      <img
        className={cn({
          icon: true,
          selected: !shownAnswer || shownAnswer === 'no',
          feedback: showFeedback,
        })}
        src="/no.svg"
        data-answer={'no'}
        onClick={() => {
          sendAnswer('no');
          setShowFeedback(true);
          setTimeout(() => setShowFeedback(false), 300);
        }}
      />
      <img
        className={cn({
          icon: true,
          selected: !shownAnswer || shownAnswer === 'yes',
          feedback: showFeedback,
        })}
        src="/yes.svg"
        data-answer={'yes'}
        onClick={() => {
          sendAnswer('yes');
          setShowFeedback(true);
          setTimeout(() => setShowFeedback(false), 300);
        }}
      />

      <style jsx>{`
        .card {
          display: flex;
          justify-content: space-between;
        }

        .icon {
          bottom: 2em;
          width: min(4em, 18vh);
          height: min(4em, 18vh);
          margin: 0.2em 0.2em 1em;
        }

        .icon:not(.selected) {
          filter: grayscale(100%);
        }

        .icon.selected.feedback {
          animation: feedback 0.3s ease 1;
        }

        @keyframes feedback {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.4);
          }
          100% {
            transform: scale(1);
          }
      `}</style>
    </div>
  );
};

YesNo.meta = {
  showHelp: true,
};

YesNo.stats = ({ data: { no, yes } }) => (
  <>
    <div className="yes">
      <i className="far fa-thumbs-up" /> {yes}
    </div>
    <div className="no">
      <i className="far fa-thumbs-down" /> {no}
    </div>
    <style jsx>{`
      .yes,
      .no {
        display: inline-block;
      }

      .yes {
        margin-right: 1em;
      }

      .yes i {
        color: green;
      }

      .no i {
        color: darkred;
      }
    `}</style>
  </>
);

export default YesNo;
