export const getSession = () =>
  'session' in sessionStorage
    ? JSON.parse(sessionStorage.getItem('session'))
    : null;
export const setSession = value =>
  sessionStorage.setItem('session', JSON.stringify(value));

export const getQuestionIndex = () =>
  parseInt(sessionStorage.getItem('questionIndex'), 10);
export const setQuestionIndex = value =>
  sessionStorage.setItem('questionIndex', value.toString());

export const getSyncQueue = () => JSON.parse(sessionStorage.getItem('queue'));
export const setSyncQueue = value =>
  sessionStorage.setItem('queue', JSON.stringify(value));
export const syncEnqueue = questionId => {
  const queue = new Set(getSyncQueue() || []);
  queue.add(questionId);
  setSyncQueue([...queue]);
};
export const syncPop = () => {
  const queue = getSyncQueue() || [];
  const item = queue.pop();
  setSyncQueue(queue);
  return item;
};

export const getAnswer = questionId =>
  sessionStorage.getItem(`answer-${questionId}`);
export const getAnswers = async () =>
  Object.keys(sessionStorage).filter(key =>
    key.startsWith('answer-').map(x => sessionStorage.getItem(x))
  );
export const setAnswer = (questionId, answer) =>
  sessionStorage.setItem(`answer-${questionId}`, answer);
