const SingleChoice = ({ answer, data: choiceLabels, sendAnswer }) => {
  const choiceIndex = answer ? parseInt(answer, 10) : -1;
  const choices = choiceLabels.split(',').map(x => x.trim());

  return (
    <div className="card">
      {choices.map((choice, i) => (
        <div key={choice}>
          <input
            type="radio"
            checked={i === choiceIndex}
            onChange={() => sendAnswer(i.toString())}
          />{' '}
          {!answer && <input style={{ display: 'none' }} defaultChecked />}
          <span
            onClick={() => {
              sendAnswer(i.toString());
            }}
          >
            {choice}
          </span>
        </div>
      ))}
      <style jsx>{`
        .card {
          margin-bottom: 1em;
        }

        .card > div {
          padding: 0.4em;
        }
      `}</style>
    </div>
  );
};

SingleChoice.stats = ({ data }) => <></>;

export default SingleChoice;
