import cn from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const DELAY = 1200;

const Stars = ({
  answer,
  answerPreview,
  data,
  previewAnswer,
  restoreAnswer,
  sendAnswer,
}) => {
  const { formatMessage } = useIntl();
  const [showFeedback, setShowFeedback] = useState(false);

  const shownAnswer = answerPreview || answer;
  const numStars = parseInt(shownAnswer, 10) || 0;
  const starLabels = data || formatMessage({ id: 'data.Stars' });
  const activeDescription =
    numStars > 0 && numStars <= 5
      ? starLabels.split(',').map(x => x.trim())[numStars - 1]
      : null;

  const handleTouch = event => {
    const touch = event.touches[0];
    if (touch) {
      const starUnderCursor = document.elementFromPoint(
        touch.clientX,
        touch.clientY
      );
      if (starUnderCursor) {
        const touchAnswer = starUnderCursor.getAttribute('data-answer');
        if (touchAnswer) {
          previewAnswer(touchAnswer);
          return;
        }
      }
    }
    // If we haven't successfully previewed an answer, restore the cached answer
    restoreAnswer();
  };

  return (
    <div className="card">
      <div className="description">{activeDescription}</div>
      <div
        onTouchStart={handleTouch}
        onTouchMove={handleTouch}
        onTouchEnd={() => {
          if (shownAnswer) {
            sendAnswer(shownAnswer, DELAY);
            setShowFeedback(true);
            setTimeout(() => setShowFeedback(false), 300);
          }
        }}
      >
        {new Array(5).fill('x').map((_, i) =>
          i < numStars ? (
            <i
              key={i}
              data-answer={i + 1}
              className={cn({
                icon: true,
                fas: true,
                'fa-star': true,
                feedback: showFeedback && numStars === i + 1,
              })}
              onClick={() => {
                sendAnswer((i + 1).toString(), DELAY);
                setShowFeedback(true);
                setTimeout(() => setShowFeedback(false), 300);
              }}
            />
          ) : (
            <i
              key={i}
              data-answer={i + 1}
              className="icon far fa-star"
              onClick={() => {
                sendAnswer((i + 1).toString(), DELAY);
                setShowFeedback(true);
                setTimeout(() => setShowFeedback(false), 300);
              }}
            />
          )
        )}
      </div>
      <style jsx>{`
        .card {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          margin-bottom: 1em;
        }

        .description {
          margin-bottom: 1em;
          user-select: none;
        }

        i {
          color: gold;
          font-size: 2em;
          letter-spacing: 0.1em;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
        }

        .feedback {
          animation: feedback 0.3s ease 1;
        }

        @keyframes feedback {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.4);
          }
          100% {
            transform: scale(1);
          }
      `}</style>
    </div>
  );
};

Stars.meta = {
  showHelp: true,
};

Stars.stats = ({ data: { avg, count, std } }) => (
  <>
    <div className="n">
      <i className="fas fa-users" /> {count}
    </div>
    <div className="avg">
      <i className="fas fa-balance-scale" /> {avg}
    </div>
    <div className="stdev">
      <i className="fas fa-square-root-alt" /> {std}
    </div>
    <style jsx>{`
      .n,
      .avg,
      .stdev {
        display: inline-block;
      }

      .n,
      .avg {
        margin-right: 1em;
      }
    `}</style>
  </>
);

export default Stars;
