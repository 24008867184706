import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getSyncQueue } from 'src/lib/store';

const Info = () => {
  const [isDone, setIsDone] = useState(getSyncQueue().length === 0);
  useEffect(
    () => {
      const timer = setInterval(() => {
        const queue = getSyncQueue();
        setIsDone(queue.length === 0);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <div className="description">
        <Card variant="outlined">
          <CardContent>
            {isDone ? (
              <FormattedMessage id="question.lastCanClose" />
            ) : (
              <FormattedMessage id="question.lastIsSaving" />
            )}
          </CardContent>
          {!isDone && <LinearProgress />}
        </Card>
      </div>
      <style jsx>{`
        .description {
          font-weight: 300;
          text-align: justify;
        }
      `}</style>
    </>
  );
};

export default Info;
