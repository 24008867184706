import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const StatusMessage = ({ children, emoji, error, loader, id }) => (
  <aside>
    {emoji && <div className="emoji">{emoji}</div>}
    {id ? <FormattedMessage id={id} /> : children}
    {loader && <LinearProgress style={{ marginTop: 20 }} />}
    {error && (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            window.location.reload();
          }}
          style={{ marginTop: 20 }}
        >
          <FormattedMessage id="status.reload" />
        </Button>
      </div>
    )}
    <style jsx>{`
      aside {
        padding: 1.5em 1em;
        font-size: 2rem;
        text-align: center;
      }

      .emoji {
        font-size: 4rem;
      }
    `}</style>
  </aside>
);

export default StatusMessage;
