import { useIntl } from 'react-intl';

const Text = ({ answer, sendAnswer }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <textarea
        className="text"
        placeholder={formatMessage({ id: 'question.text.placeholder' })}
        onInput={e => sendAnswer(e.currentTarget.value)}
        defaultValue={answer}
      />
      <style jsx>{`
        .text {
          width: 100%;
          height: 100%;
          border: 1px solid lightgray;
          border-radius: 5px;
          padding: 0.3em;
        }
      `}</style>
    </>
  );
};

Text.stats = ({ data }) => (
  <>
    <ul>
      {data.map((x, i) => (
        <li key={i}>{x}</li>
      ))}
    </ul>
    <style jsx>{`
      ul {
        padding: 0 0.5em 0 1.5em;
      }
    `}</style>
  </>
);

export default Text;
