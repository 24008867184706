import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import BusinessIcon from '@material-ui/icons/Business';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import { navigate, routes } from '@redwoodjs/router';
import React, { useContext } from 'react';
import { LocaleContext } from 'src/components/LocaleProvider';

const AppMenu = ({ open, onClose }) => {
  const { language, setLanguage } = useContext(LocaleContext);
  return (
    <Drawer variant="persistent" anchor="right" open={open}>
      <div style={{ minWidth: 240 }}>
        <IconButton onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            navigate(routes.datenschutz());
          }}
        >
          <ListItemIcon>
            <FilterDramaIcon />
          </ListItemIcon>
          <ListItemText primary="Datenschutz" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            navigate(routes.impressum());
          }}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Impressum" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          disabled={language === 'de'}
          onClick={() => {
            setLanguage('de');
          }}
        >
          <ListItemIcon>
            <SvgIcon viewBox="0 0 5 3">
              <rect
                id="black_stripe"
                width="5"
                height="3"
                y="0"
                x="0"
                fill="#000"
              />
              <rect
                id="red_stripe"
                width="5"
                height="2"
                y="1"
                x="0"
                fill="#D00"
              />
              <rect
                id="gold_stripe"
                width="5"
                height="1"
                y="2"
                x="0"
                fill="#FFCE00"
              />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Deutsch" />
        </ListItem>
        <ListItem
          button
          disabled={language === 'en'}
          onClick={() => {
            setLanguage('en');
          }}
        >
          <ListItemIcon>
            <SvgIcon viewBox="0 0 60 30">
              <clipPath id="t">
                <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
              </clipPath>
              <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
              <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
              <path
                d="M0,0 L60,30 M60,0 L0,30"
                clipPath="url(#t)"
                stroke="#cf142b"
                strokeWidth="4"
              />
              <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
              <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6" />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="English" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default AppMenu;
