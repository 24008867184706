import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const Info = ({ description }) => (
  <>
    <div className="description">
      <Card variant="outlined">
        <CardContent>{description}</CardContent>
      </Card>
    </div>
    <style jsx>{`
      .description {
        font-weight: 300;
        text-align: justify;
      }
    `}</style>
  </>
);

export default Info;
