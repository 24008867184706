import Info from './Info';
import Last from './Last';
import SingleChoice from './SingleChoice';
import Stars from './Stars';
import Text from './Text';
import YesNo from './YesNo';

export default {
  Info,
  Last,
  SingleChoice,
  Stars,
  Text,
  YesNo,
};
