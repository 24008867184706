import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';

const primaryColor = process.env.REDWOOD_ENV_PRIMARY_COLOR;
const secondaryColor = process.env.REDWOOD_ENV_SECONDARY_COLOR;

const theme = createMuiTheme({
  palette: {
    ...(primaryColor ? { primary: { main: primaryColor } } : {}),
    ...(secondaryColor ? { secondary: { main: secondaryColor } } : {}),
  },
  typography: {
    htmlFontSize: 16,
  },
});

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
