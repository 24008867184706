import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import AppMenu from 'src/components/AppMenu';
import ThemeProvider from 'src/components/ThemeProvider';
import { setQuestionIndex } from 'src/lib/store';
import 'typeface-roboto';

const AppLayout = ({
  children,
  logo = process.env.REDWOOD_ENV_LOGO_URL,
  logoTitle = process.env.REDWOOD_ENV_LOGO_TITLE,
  title,
  scroll = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Initialise GA
  if (!window.localStorage.gaOptout) {
    ReactGA.initialize('UA-151581457-1');
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <>
      <Helmet>
        <title>{title || 'MeinArzt.top'}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css"
          media="print"
          onLoad="this.media='all'"
        />
      </Helmet>

      <ThemeProvider>
        <header>
          <AppBar position="static">
            <Toolbar>
              <button
                type="button"
                className="titleButton"
                onClick={() => {
                  setQuestionIndex(0);
                  window.location.reload();
                }}
              >
                {logo && <img className="logo" src={logo} alt={title} />}
                {logoTitle && (
                  <div className="title">
                    <Typography variant="h5" noWrap>
                      {logoTitle}
                    </Typography>
                  </div>
                )}
              </button>
              {!menuIsOpen && (
                <IconButton
                  aria-label="display more actions"
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    setMenuIsOpen(true);
                  }}
                >
                  <MenuIcon
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                  />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </header>

        <main>{children}</main>

        <footer />

        <AppMenu
          open={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
          }}
        />
      </ThemeProvider>

      <style jsx global>{`
        :root {
          --header-color: #4a90e2;
        }

        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        html,
        body {
          position: ${scroll ? 'initial' : 'fixed'};
          overflow: ${scroll ? 'initial' : 'hidden'};
        }

        html,
        body,
        #redwood-app,
        main {
          width: 100%;
          height: 100%;
          min-width: 320px;
        }

        body {
          margin: 0;
          padding: 0;
          font-family: 'Roboto', sans-serif;
          font-size: 22px;
          font-weight: 400;
        }

        #redwood-app {
          display: grid;
          grid-template-rows: auto 1fr auto;
          grid-template-columns: 100%;
        }

        button,
        input,
        textarea {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
        }

        button:focus {
          outline: none;
        }

        table {
          margin: auto;
        }

        p {
          margin: 0.5em 0;
        }

        button,
        i {
          user-select: none;
          touch-action: manipulation;
        }

        i {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        .titleButton {
          display: flex;
          align-items: center;
          flex-grow: 1;
          text-align: left;
          background: none;
          border: none;
          margin: 0;
          padding: 0;
          color: white;
        }

        .logo {
          height: 36px;
        }

        .title {
          padding-left: 0.5em;
        }

        @media print {
          header,
          footer,
          form,
          nav {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default AppLayout;
